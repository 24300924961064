<template>
  <div>
    <!-- Filters -->
    <audit-list-filters
      v-if="false"
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refAuditListTable"
        class="position-relative"
        :items="fetchAuditLog"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
      >
        <!-- Column: AuditLog -->
        <template #cell(actor)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.actor.avatar"
                :text="avatarText(data.item.actor.name)"
                :variant="`light-primary`"
                :to="{ name: 'apps-user-view', params: { id: data.item.actor.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-auditLogs-view', params: { id: data.item.actor.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.actor.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.actor.username }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(ipAddress)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ data.item.identity.ipAddress }}
          </b-badge>
        </template>
        <!-- Column: Status -->
        <template #cell(device)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ deviceLabel(data.item.access.userAgent) }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(description)="data">
          <div class="text-nowrap">
            <span v-html="data.item.description"></span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(deviceId)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ data.item.identity.deviceId }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(createdAt)="data">
          <span class="text-nowrap">
            {{ moment(data.value).utc().format('L HH:mm:ss') }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item :to="{ name: 'apps-auditLogs-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-auditLogs-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEntries"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import AuditListFilters from './AuditListFilters.vue';
import useAuditList from './useAuditList';
import auditStoreModule from '../auditStoreModule';
import uaParser from 'ua-parser-js';
import moment from 'moment';

export default {
  components: {
    AuditListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    deviceLabel(userAgent) {
      const ua = new uaParser(userAgent);
      return `${ua.getBrowser().name} ${ua.getBrowser().major} / ${ua.getOS().name} ${ua.getOS().version}`;
    },
  },
  setup() {
    const AUDIT_APP_STORE_MODULE_NAME = 'app-audit';

    // Register module
    if (!store.hasModule(AUDIT_APP_STORE_MODULE_NAME)) store.registerModule(AUDIT_APP_STORE_MODULE_NAME, auditStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUDIT_APP_STORE_MODULE_NAME)) store.unregisterModule(AUDIT_APP_STORE_MODULE_NAME);
    });

    const isAddNewAuditLogSidebarActive = ref(false);

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Manager', value: 'manager' },
      { label: 'Auditor', value: 'auditor' },
      { label: 'Accountant', value: 'accountant' },
      { label: 'Proctor', value: 'proctor' },
      { label: 'Staff', value: 'staff' },
      { label: 'Student', value: 'student' },
    ];

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];

    const {
      fetchAuditLog,
      tableColumns,
      perPage,
      currentPage,
      totalEntries,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAuditListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = useAuditList();

    return {
      // Sidebar
      isAddNewAuditLogSidebarActive,

      fetchAuditLog,
      tableColumns,
      perPage,
      currentPage,
      totalEntries,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAuditListTable,
      refetchData,

      // Filter
      avatarText,

      // UI

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,

      //
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
